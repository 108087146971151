.about-stats-section {
    padding: 4rem 0;
    background-color: var(--background);
  }
  
  .about-stats-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    align-items: center;
  }
  
  .about-stats-image img {
    width: 80%;
    height: auto;
    border-radius: 0.5rem;
    margin-left: 100px;
  }
  
  .about-stats-content h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: var(--text-color);
  }
  
  .about-stats-numbers {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
  
  .about-stat-item h3 {
    font-size: 2rem;
    color: var(--primary-color);
    margin-bottom: 0.5rem;
  }
  
  /* Responsive */
  @media (max-width: 768px) {
    .about-stats-grid {
      grid-template-columns: 1fr;
    }
    .about-stats-image img {
      margin: 0 50px;
    }
    .about-stats-content {
      margin: 0 50px;
    }
  }
  