.blog-banner {
    width: 100%;
    margin-bottom: 2rem;
    background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
    border-radius: 12px;
    overflow: hidden;
  }
  
  .blog-banner-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    gap: 2rem;
  }
  
  .blog-banner-text {
    flex: 1;
  }
  
  .blog-banner-text h2 {
    font-size: 2rem;
    font-weight: 700;
    color: #2d3748;
    margin-bottom: 1rem;
  }
  
  .blog-banner-text p {
    font-size: 1.1rem;
    color: #4a5568;
    margin-bottom: 1.5rem;
    line-height: 1.6;
  }
  
  .blog-banner-button {
    padding: 0.75rem 1.5rem;
    background-color: #4299e1;
    color: white;
    border: none;
    border-radius: 6px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .blog-banner-button:hover {
    background-color: #3182ce;
  }
  
  .blog-banner-image {
    flex: 1;
    max-width: 500px;
  }
  
  .blog-banner-image img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    object-fit: cover;
  }
  
  .blog-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    margin-top: 2rem;  /* Add space between banner and blog cards */
  }
  
  @media (max-width: 768px) {
    .blog-main-content {
      flex-direction: column;
    }
  
    .blog-content {
      width: 100%;
      max-width: 100%;
      margin: 0 50px;
    }
  
    .blog-banner-content {
      flex-direction: column;
      text-align: center;
    }
    
    .blog-banner-image {
      max-width: 100%;
    }
    
    .blog-banner-text {
      margin-bottom: 1.5rem;
    }
  }
  
  @media (max-width: 576px) {
    .blog-list {
      grid-template-columns: 1fr;
    }
  }