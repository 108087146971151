.about-hero {
  position: relative;
  height: 500px;
  background-color: #2563eb;
  overflow: hidden;
}

.about-hero-overlay {
  position: absolute;
  inset: 0;
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  z-index: 1;
}

.about-hero-overlay.active {
  opacity: 0.2;
}

.about-hero-content {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
}

.about-hero h1 {
  font-size: 3rem;
  color: #ffffff;
  margin-bottom: 1rem;
  max-width: 600px;
}

.about-hero p {
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.125rem;
  margin-bottom: 2rem;
  max-width: 500px;
}

.about-cta-button {
  background-color: #3C4DE0;
  color: white;
  border: none;
  padding: 1rem 2rem;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 250px;
  transition: background-color 0.3s ease;
}

.about-cta-button:hover {
  background-color: #2a3ab0;
}

.about-cta-button img {
  margin-left: 1rem;
}

/* Animation for smooth background transition */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.2;
  }
}

@media (max-width: 768px) {
  .about-hero h1 {
    font-size: 2.5rem;
  }
}

@media (max-width: 480px) {
  .about-hero h1 {
    font-size: 2rem;
  }
}


/* .about-hero {
    position: relative;
    height: 500px;
    background-color: #2563eb;
    overflow: hidden;
  }
  
  .about-hero-overlay {
    position: absolute;
    inset: 0;
    background-image: url('../../../../assets/images/home/buy-sell-rent-background-image.svg');
    background-size: cover;
    opacity: 0.2;
  }
  
  .about-hero-content {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .about-hero h1 {
    font-size: 3rem;
    color: #ffffff;
    margin-bottom: 1rem;
    max-width: 600px;
  }
  
  .about-hero p {
    color: rgba(255, 255, 255, 0.9);
    font-size: 1.125rem;
    margin-bottom: 2rem;
    max-width: 500px;
  }

  .about-cta-button {
    background-color: #3C4DE0;
    color: white;
    border: none;
    padding: 1rem 2rem;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 250px;
  }
  
  .about-cta-button img {
    margin-left: 1rem;
  }

  @media (max-width: 768px) {
    .about-hero h1 {
      font-size: 2.5rem;
    }
  }
  
  @media (max-width: 480px) {
    .about-hero h1 {
      font-size: 2rem;
    }
  } */