.customize-search {
  background-color: #FBF2E8; 
  padding: 4rem 2rem; 
  text-align: center; 
  position: relative; 
}

/* New Hiring Notification Styles */
.hiring-notification {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  background-color: #1428DA;
  color: #FFFFFF;
  padding: 10px 15px;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.hiring-notification:hover {
  background-color: #0A1FAA;
  transform: scale(1.05);
}

.hiring-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.hiring-text {
  font-weight: bold;
  font-size: 14px;
  white-space: nowrap;
}

.customize-search h2 {
  font-size: 36px;
  color: #3C4DE0;
  margin-bottom: 1rem;
}

.customize-search > p {
  color: #000;
  margin-bottom: 2rem;
}

.card-container {
  display: flex;
  justify-content: center; /* Ensures cards are centered */
  align-items: center; /* Aligns cards vertically */
  gap: 1.5rem; /* Reduces the gap between the cards */
  flex-wrap: wrap; /* Ensures cards wrap on smaller screens */
}

.card {
  width: 300px; /* Reduce the card width */
  height: 250px; /* Reduce the card height */
  background-color: #FFFFFF;
  color: #1428DA;
  padding: 1.5rem; /* Adjust padding for smaller cards */
  border-radius: 8px;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add subtle shadow for depth */
}

.card:hover,
.card:focus {
  background-color: #1428DA;
  color: #FFFFFF;
}

.card:hover p,
.card:hover h3 {
  color: #FFFFFF;
}

.card img {
  width: 48px; /* Reduce the size of the image */
  height: 48px;
  margin-bottom: 0.75rem; /* Adjust spacing */
}

.card h3 {
  font-size: 20px; /* Decrease the font size */
  margin-bottom: 0.75rem;
}

.card p {
  font-size: 14px; /* Decrease the font size */
  color: #000;
}

@media (max-width: 1200px) {
  .card-container {
      flex-wrap: wrap;
  }

  .card {
      width: calc(50% - 1rem); 
  }

  .hiring-notification {
    position: fixed;
    top: 10px;
    right: 10px;
  }
}

@media (max-width: 768px) {
  .card-container {
    flex-direction: column;
  }

  .card {
    width: 100%;
    margin-bottom: 1rem;
  }
  .customize-search {
    padding-right: 5rem;
  }

  .hiring-notification {
    position: fixed;
    top: 10px;
    right: 80px;
    padding: 8px 12px;
    z-index: 1000;
    max-width: calc(100vw - 20px);
    overflow: hidden; 
  }

  .hiring-icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }

  .hiring-text {
    font-size: 12px;
  }
}