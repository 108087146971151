.testimonial {
  background-color: inherit;
  padding: 4rem 2rem;
  height: auto;
  /* height: 200px; */

}

.testimonial-container {
  max-width: 1007px;
  /* margin: -250px auto; */
  margin: 0 auto;
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.testimonial-left {
  width: 270px;
  position: relative;
}

.testimonial-image {
  width: 170px;
  height: 244px;
  overflow: hidden;
  padding: 50px;
}

.testimonial-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.testimonial-info {
  position: absolute;
  bottom: 30px;
  right: -100px;
  width: 200px;
  height: 150px;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.testimonial-info h3 {
  font-size: 14px;
  margin-top: 30px;
  margin-bottom: -0.5rem;
  color: #000;
  text-align: center;
}

.testimonial-info p {
  font-size: 14px;
  color: #000;
  line-height: 1.2;
}

.testimonial-content {
  flex: 1;
  padding: 5rem 10rem 2rem 10rem;
  background-color: #1223C8;
}

.testimonial-content h2 {
  font-size: 28px;
  margin-bottom: 1rem;
  color: #FFFFFF;
}

.testimonial-content p {
  margin-bottom: 2rem;
  color: #FFFFFF;
}

.testimonial-navigation {
  display: flex;
  justify-content: flex-end;
}

.testimonial-navigation button {
  border: none;
  padding: 0.5rem 1rem;
  margin-left: 1rem;
  cursor: pointer;
  font-size: 24px;
  color: #FFFFFF;
}

.testimonial-navigation .prev-btn {
  background-color: transparent;
}

.testimonial-navigation .next-btn {
  background-color: #DA7B14;
}

@media (max-width: 1024px) {
  .testimonial-container {
    flex-direction: column;
    margin: -150px auto 0;
  }

  .testimonial-left {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .testimonial-image {
    width: 140px;
    height: 200px;
    padding: 20px;
  }

  .testimonial-info {
    position: relative;
    right: 0;
    bottom: 0;
    width: 80%;
    max-width: 300px;
    margin: -50px auto 20px;
  }

  .testimonial-content {
    padding: 2rem;
  }

  .testimonial-content h2 {
    font-size: 24px;
  }

  .testimonial-content p {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .testimonial-container {
    flex-direction: column;
  }

  .testimonial-left {
    width: 100%;
    padding: 1rem;
  }

  .testimonial-image {
    width: 100%;
    height: auto;
    padding: 0;
  }

  .testimonial-info {
    position: static;
    width: 100%;
    margin: 1rem 0;
    
  }


  .testimonial-content {
    padding: 2rem 1rem;
  }

  .testimonial-content h2 {
    font-size: 24px;
  }
}


@media (max-width: 480px) {
  .testimonial-container {
    margin-top: -50px;
  }

  .testimonial-image {
    width: 100px;
    height: 140px;
    padding: 10px;
  }

  .testimonial-info {
    width: 90%;
  }

  .testimonial-content {
    padding: 1.5rem;
  }

  .testimonial-content h2 {
    font-size: 18px;
  }

  .testimonial-navigation button {
    padding: 0.3rem 0.8rem;
    font-size: 18px;
  }
}



/* .testimonial {
  background-color: inherit;
  padding: 4rem 2rem;
  height: 200px;
}

.testimonial-container {
  max-width: 1007px;
  margin: -250px auto;
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.testimonial-left {
  width: 270px;
  position: relative;
}

.testimonial-image {
  width: 170px;
  height: 244px;
  overflow: hidden;
  padding: 50px;
}

.testimonial-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.testimonial-info {
  position: absolute;
  bottom: 30px;
  right: -100px;
  width: 200px;
  height: 150px;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
}

.testimonial-info h3 {
  font-size: 14px;
  margin-top: 30px;
  margin-bottom: -0.5rem;
  color: #000;
  text-align: center;
}

.testimonial-info p {
  font-size: 14px;
  color: #000;
  line-height: 1.2;
}

.testimonial-content {
  padding: 5rem 10rem 2rem 10rem;
  background-color: #1223C8;
}

.testimonial-content h2 {
  font-size: 28px;
  margin-bottom: 1rem;
  color:#FFFFFF;
}

.testimonial-content p {
  margin-bottom: 2rem;
  color:#FFFFFF;
}

.testimonial-navigation {
  display: flex;
  justify-content: flex-end;
}

.testimonial-navigation button {
  border: none;
  padding: 0.5rem 1rem;
  margin-left: 1rem;
  cursor: pointer;
  font-size: 24px;
  color: #FFFFFF;
}

.testimonial-navigation .prev-btn {
  background-color: transparent;
}

.testimonial-navigation .next-btn {
  background-color: #DA7B14;
}

@media (max-width: 768px) {
  .testimonial-container {
    flex-direction: column;
  }

  .testimonial-left {
    width: 100%;
    margin-bottom: 2rem;
  }

  .testimonial-image {
    width: 100%;
    height: auto;
    aspect-ratio: 270 / 344;
  }

  .testimonial-info {
    right: 0;
    bottom: -50px;
    width: 80%;
    height: auto;
    aspect-ratio: 4 / 3;
  }

  .testimonial-content {
    width: 100%;
  }
} */