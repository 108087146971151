.information-panel {
    color: white;
    margin-bottom: 2rem;
  }
  
  @media (min-width: 1024px) {
    .information-panel {
      width: 66.666667%;
      margin-bottom: 0;
      padding-right: 2rem;
      color: white;
    }
  }
  
  .information-panel h2 {
    font-size: 1.875rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: white;
  }
  
  .information-panel p {
    margin-bottom: 1rem;
    color: white;
    line-height: 2.2;
  }
  
  .divider {
    width: 6rem;
    height: 0.25rem;
    background-color: white;
    margin-bottom: 1rem;
  }
  
  .steps {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  
  @media (min-width: 768px) {
    .steps {
      flex-direction: row;
    }
  }
  
  .step h3 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }