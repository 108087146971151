.button {
    display: inline-block;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .button:hover {
    opacity: 0.9;
  }
  
  .button:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  }
  
  /* Default button style (you can customize this based on your design system) */
  .button {
    background-color: var(--color-primary, #4a5568);
    color: white;
  }
  
  /* You can add more button variations here, for example: */
  .button-secondary {
    background-color: #718096;
    color: white;
  }
  
  .button-outline {
    background-color: transparent;
    color: var(--color-primary, #4a5568);
    border: 1px solid var(--color-primary, #4a5568);
  }
  
  /* Add responsive styles if needed */
  @media (max-width: 640px) {
    .button {
      padding: 0.4rem 0.8rem;
      font-size: 0.875rem;
    }
  }