.aboutPage-cta-section {
    background-color: #3769D6;
    padding: 4rem 0;
    text-align: center;
    
  }
  
  .aboutPage-cta-container > .aboutPage-cta-text, h2 {
    color: #fff;
  }

  
  /* .aboutPage-cta-button {
    background-color: var(--white);
    color: var(--primary-color);
    margin: 0 auto;
    border-radius: 0.5rem;
    transition: background-color 0.3s;
  } */

  .aboutPage-cta-button {
    background-color: #3C4DE0;
    color: white;
    border: none;
    padding: 1rem 2rem;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 250px;
    margin: 0 auto;
  }
  
  .aboutPage-cta-button img {
    margin-left: 1rem;
  }
  
  /* .about-cta-button:hover {
    background-color: #f3f4f6;
  } */
  
  /* Responsive */
  @media (max-width: 480px) {
    .aboutPage-cta-section h2 {
      font-size: 1.5rem;
    }
  }
  