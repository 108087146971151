.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  width: 100%;
}

.property-list-section {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .section-header > h2 {
    color: #1D1F2C !important;
    font-size: 20px;
    font-weight: 600;
  }
  
  .add-property-btn {
    background-color: var(--primary-color);
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
  }

  .add-property-btn:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    background-color: #ccc;
  }
  
  .edit-btn:disabled,
  .delete-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .table-container {
    width: 100%;
    position: relative;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    text-align: left;
    padding: 12px;
    border-bottom: 1px solid #ddd;
  }
  
  th {
    background-color: #f8f8f8;
    font-weight: bold;
  }
  
  .edit-btn, .delete-btn {
    padding: 5px 10px;
    margin-right: 5px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .edit-btn {
    background-color: #ffc107;
    color: #000;
  }
  
  .delete-btn {
    background-color: #dc3545;
    color: white;
  }