/* .signup-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f0f2f5;
  } */
  .signup-page { 
    display: flex; 
    justify-content: center; 
    align-items: center; 
    min-height: 100vh; 
    background-image: url('../../assets/images/home/footer-background.svg');  /* Update path based on your project structure */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-color: #f0f2f5; /* Fallback color in case image fails to load */
} 
  
  .signup-form-container {
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
  }
  
  .signup-form-container h2 {
    margin-bottom: 1.5rem;
    text-align: center;
    color: #333;
  }
  
  .signup-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: #333;
  }
  
  .form-group input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .submit-button {
    background-color: #4CAF50;
    color: white;
    padding: 0.75rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;
  }
  
  .submit-button:hover {
    background-color: #45a049;
  }
  
  .submit-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .error-message {
    color: #ff0000;
    margin-bottom: 1rem;
    text-align: center;
  }