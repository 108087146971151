.dashboard {
  padding: 20px 20px 20px 20px;
  width: 100%;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.dashboard-header  h2 {
  color: #3C4DE0;
}

.dashboard-header-right {
  display: flex;
  align-items: center;
}

.dashboard-refresh-icon {
  cursor: pointer;
  width: 24px;
  height: 24px;
  margin-right: 16px;
  color: #4A4C56;
}

.dashboard-date-picker-btn {
  display: flex;
  align-items: center;
  background-color: #fff;
  color: #4A4C56;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
  width: 140px;
  height: 40px;
}

.dashboard-date-picker-icon {
  margin-right: 8px;
  width: 16px;
  height: 16px;
}

.dashboard-property-summary-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 20px;
}

.dashboard-content {
  display: flex;
  gap: 20px;
}

.dashboard-content > * {
  flex: 1;
}

@media (max-width: 1024px) {
  .dashboard-content {
    flex-direction: column;
  }
}