.property-summary-card {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.icon-wrapper {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-svg {
  width: 24px;
  height: 24px;
}

.more-icon {
  cursor: pointer;
  color: #777;
}

h3 {
  margin-bottom: 10px;
  font-size: 18px;
}

/* Card Numbers */
.card-numbers {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.property-count {
  font-size: 24px;
  font-weight: bold;
  margin-right: 10px;
}

/* Percentage Container */
.percentage-container {
  background-color: #DDFFE2;
  color: #00B518;
  border-radius: 4px;
  width: 48px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
}

p {
  color: #777;
  font-size: 14px;
}