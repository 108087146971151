.blog-page {
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #fff;
}

.blog-content-wrapper {
  width: 90%;
  margin: 0 auto;
  padding: 20px;
}

.blog-filter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
}

.blog-header-icons {
  display: flex;
  gap: 15px;
}

.blog-search-container {
  display: flex;
  align-items: center;
  position: relative;
  transition: all 0.3s ease;
  width: 40px;
}

.blog-search-container.blog-active {
  width: 250px;
  background-color: #f5f5f5;
  border-radius: 20px;
  padding: 0 10px;
}

.blog-search-input {
  width: 100%;
  padding: 8px 35px 8px 15px;
  border: none;
  background: transparent;
  outline: none;
  font-size: 14px;
  opacity: 0;
  transition: all 0.3s ease;
}

.blog-search-container.active .blog-search-input {
  opacity: 1;
}

.blog-icon-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  z-index: 2;
}

.blog-search-icon,
.blog-candy-icon {
  font-size: 20px;
  color: #333;
  transition: all 0.3s ease;
}
/* .icon-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
} */

/* .search-icon,
.candy-icon {
  font-size: 20px;
  color: #000;
} */

.blog-main-content {
  display: flex;
  gap: 30px;
}

.blog-content {
  flex: 0 0 70%;
  max-width: 70%;
}

.blog-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

@media (max-width: 768px) {
  .blog-main-content {
    flex-direction: column;
  }

  .blog-content {
    width: 100%;
    max-width: 100%;
    margin: 0 50px;
  }

  .blog-search-container.blog-active {
    width: 200px;
  }

}

@media (max-width: 576px) {
  .blog-list {
    grid-template-columns: 1fr;
  }

  .blog-search-container.blog-active {
    width: 150px;
  }
}