.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    width: 500px;
    max-width: 90%;
    max-height: 90vh;
    overflow-y: auto;
    animation: fadeIn 0.3s ease-in-out;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  .modal-header {
    margin-bottom: 20px;
  }
  
  .header-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .modal-logo {
    width: 40px;
    height: 40px;
  }
  
  .modal-title {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
  }
  
  .tabs {
    display: flex;
  }
  
  .tab {
    padding: 10px 20px;
    border: none;
    background: none;
    cursor: pointer;
  }
  
  .tab.active {
    border-bottom: 2px solid #4A4C56;
  }
  
  .property-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .form-row {
    display: flex;
    gap: 15px;
  }
  
  .form-row .form-group {
    flex: 1;
  }
  
  .form-group label {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .form-group input,
  .form-group textarea,
  .form-group select {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ddd;
    width: 100%;
  }
  
  textarea {
    resize: vertical;
    min-height: 100px;
  }
  
  .toggle-buttons {
    display: flex;
    gap: 10px;
  }
  
  .toggle-btn {
    flex: 1;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: white;
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .toggle-btn.active {
    background: #00b518;
    color: white;
    border-color: #00b518;
  }
  
  .file-input {
    padding: 8px;
    border: 1px dashed #ddd;
    border-radius: 4px;
  }
  
  .image-preview {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  
  .preview-item {
    padding: 4px 8px;
    background: #f0f0f0;
    border-radius: 4px;
    font-size: 0.9em;
  }
  
  .form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
  }
  
  .save-btn {
    background-color: #00b518;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .cancel-btn {
    background-color: #dc3545;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
  }