.blog-recent-posts {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .blog-recent-post-item {
    display: flex;
    gap: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #e5e5e5;
  }
  
  .blog-recent-post-item:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
  
  .blog-recent-post-image {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 6px;
  }
  
  .blog-recent-post-content {
    flex: 1;
  }
  
  .blog-recent-post-title {
    font-size: 0.875rem;
    font-weight: 500;
    color: #1a1a1a;
    margin-bottom: 0.5rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    cursor: pointer;
    transition: color 0.2s ease;
  }
  
  .blog-recent-post-title:hover {
    color: #2563eb;
  }
  
  .blog-recent-post-meta {
    font-size: 0.75rem;
    color: #666;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .blog-meta-separator {
    color: #999;
  }