.pagination {
    display: flex;
    justify-content: center;
    margin: 30px 0;
  }
  
  .pagination button {
    margin: 0 5px;
    padding: 10px 20px;
    border: 1px solid #ccc;
    background-color: white;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .pagination .active {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
  }
  