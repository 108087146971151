.admin-dashboard-statistics-section {
  background-color: white;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin: 24px;
}

.admin-dashboard-statistics-section h4 {
  color: #666;
  font-size: 18px;
  margin-bottom: 16px;
}

.admin-dashboard-statistics-graph {
  height: 250px;
  background-color: #f3f4f6;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .admin-dashboard-statistics-section {
    margin: 16px;
  }
}




/* .statistics-section {
    background-color: white;
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    margin: 24px;
  }
  
  .statistics-section h4 {
    color: #666;
    font-size: 18px;
    margin-bottom: 16px;
  }
  
  .statistics-graph {
    height: 250px;
    background-color: #f3f4f6;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .statistics-graph img {
    max-width: 100%;
  }
  

@media (max-width: 768px) {
    .statistics-section {
      margin: 16px;
    }
  } */