.job-vacancies-ad {
  background-color: #f0f2f5;
  padding: 48px 0;
}

.job-vacancies-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 16px;
}

.job-section-title {
  text-align: center;
  margin-bottom: 32px;
  color: #1E1E2D;
  font-size: 2rem;
}

.job-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
}

.job-card {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.job-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.job-image-container {
  height: 250px;
  overflow: hidden;
}

.job-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.job-card:hover .job-image {
  transform: scale(1.1);
}

.job-content {
  padding: 24px;
}

.job-title {
  font-size: 1.5rem;
  color: #1E1E2D;
  margin-bottom: 16px;
}

.job-description {
  color: #777;
  margin-bottom: 16px;
}

.job-details-btn {
  background-color: #2697FF;
  color: #ffffff;
  border: none;
  padding: 12px 24px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.job-details-btn:hover {
  background-color: #1a7bff;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .job-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .job-grid {
    grid-template-columns: 1fr;
  }
}



/* .job-vacancies-ad {
    background-color: var(--background-color);
    padding: var(--spacing-12) 0;
  }
  
  .job-vacancies-ad .container {
    max-width: var(--container-max-width);
    margin: 0 auto;
    padding: 0 var(--container-padding);
  }
  
  .section-title {
    text-align: center;
    margin-bottom: var(--spacing-8);
    color: var(--secondary-color);
    font-size: var(--font-size-3xl);
  }
  
  .job-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--spacing-6);
  }
  
  .job-card {
    background-color: var(--white);
    border-radius: var(--border-radius-lg);
    box-shadow: var(--card-shadow);
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .job-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  }
  
  .job-image-container {
    height: 250px;
    overflow: hidden;
  }
  
  .job-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .job-card:hover .job-image {
    transform: scale(1.1);
  }
  
  .job-content {
    padding: var(--spacing-6);
  }
  
  .job-title {
    font-size: var(--font-size-2xl);
    color: var(--secondary-color);
    margin-bottom: var(--spacing-4);
  }
  
  .job-description {
    color: var(--light-text-color);
    margin-bottom: var(--spacing-4);
  }
  
  .job-details-btn {
    background-color: var(--primary-color);
    color: var(--white);
    border: none;
    padding: var(--spacing-3) var(--spacing-6);
    border-radius: var(--border-radius-md);
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .job-details-btn:hover {
    background-color: #1a7bff;
  }
  
  @media (max-width: 1024px) {
    .job-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 768px) {
    .job-grid {
      grid-template-columns: 1fr;
    }
  } */