.blog-tabs-filter {
  display: flex;
  gap: 10px;
}

.blog-tabs-filter button {
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.blog-tabs-filter .blog-active {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}


@media (max-width: 576px) {
  /* .filter-header {
    flex-direction: column;
    gap: 15px;
  } */

  .blog-tabs-filter {
    width: 100%;
    overflow-x: auto;
  }
}


/* .tabs-filter {
    display: flex;
    margin-bottom: 20px;
    flex-direction: row;
  }
  
  .tabs-filter button {
    background-color: white;
    border: 1px solid #ccc;
    padding: 10px 20px;
    margin-right: 10px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .tabs-filter .active {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
  } */
  