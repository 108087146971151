.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-container {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 600px;
    max-height: 80vh;
    overflow-y: auto;
    position: relative;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .modal-header h2 {
    margin: 0;
    font-size: 1.25rem;
    color: #333;
  }
  
  .modal-close-btn {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #888;
    transition: color 0.3s ease;
  }
  
  .modal-close-btn:hover {
    color: #333;
  }
  
  .modal-body {
    padding: 16px;
  }
  
  .modal-footer {
    display: flex;
    justify-content: flex-end;
    padding: 16px;
    border-top: 1px solid #e0e0e0;
    gap: 10px;
  }
  
  .modal-btn {
    padding: 10px 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .modal-btn-cancel {
    background-color: #f0f0f0;
    color: #333;
    border: 1px solid #d0d0d0;
  }
  
  .modal-btn-cancel:hover {
    background-color: #e0e0e0;
  }
  
  .modal-btn-apply {
    background-color: #2697FF;
    color: white;
    border: none;
  }
  
  .modal-btn-apply:hover {
    background-color: #1a7bff;
  }