.navbar {
  background-color: #0B167C;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: box-shadow 0.3s ease;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.navbar.scrolled {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logo img {
  height: 40px;
}

.nav-content {
  display: flex;
  justify-content: center; /* Center the links */
  align-items: center;
  flex-grow: 1;
}

.nav-links {
  display: flex;
  list-style-type: none;
  margin: 0 auto;
  padding: 0;
}

.nav-links li {
  margin: 0 1rem;
}

.nav-links a {
  color: white;
  text-decoration: none;
  transition: color 0.3s ease;
}

.nav-links a:hover,
.nav-links a.active {
  color: #DA7B14;
}


.auth-buttons, 
.navbar-welcome {
  color: whitesmoke;
  margin-left: auto; 
  display: flex;
  align-items: center;
}

.auth-buttons {
  margin-left: 1rem;
}


.login-btn,
.signup-btn,
.logout-btn {
  width: 120px;
  height: 45px;
  border: none;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.login-btn {
  background-color: transparent;
  color: #DA7B14;
  margin-right: 1rem;
}

.signup-btn {
  background-color: #DA7B14;
  color: white;
}

.logout-btn {
  background-color: #DA7B14;
  color: white;
  margin-left: 1rem;
}

.login-btn:hover {
  background-color: rgba(218, 123, 20, 0.1);
}

.signup-btn:hover, .logout-btn:hover {
  background-color: #c16c12;
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
}

.hamburger {
  display: block;
  width: 24px;
  height: 2px;
  background-color: white;
  position: relative;
  transition: background-color 0.3s ease;
}

.hamburger::before,
.hamburger::after {
  content: '';
  position: absolute;
  width: 24px;
  height: 2px;
  background-color: white;
  transition: transform 0.3s ease;
}

.hamburger::before {
  top: -6px;
}

.hamburger::after {
  bottom: -6px;
}

.menu-open .hamburger {
  background-color: transparent;
}

.menu-open .hamburger::before {
  transform: rotate(45deg) translate(4px, 4px);
}

.menu-open .hamburger::after {
  transform: rotate(-45deg) translate(4px, -4px);
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}


@media (max-width: 768px) {

  .navbar-container {
    padding: 1rem;
    justify-content: space-between;
  }

  .menu-toggle {
    display: inline;
  }

  .nav-content {
    display: none;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #0B167C;
    padding: 1rem;
  }

  .menu-open .nav-content {
    display: flex;
  }

  .nav-links {
    flex-direction: column;
  }

  .nav-links li {
    margin: 0.5rem 0;
  }

  .auth-buttons,
  .navbar-welcome {
    color: whitesmoke;
    margin-top: 1rem;
    width: 100%;
    justify-content: center;
  }


  .login-btn,
  .signup-btn,
  .logout-btn {
    width: 48%;
    margin: 0;
  }
}