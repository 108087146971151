.offer-page {
    width: 100%;
  }
  
  .offer-page-latest-offers,
  .offer-page-for-rent,
  .offer-page-for-sale {
    padding: 4rem 1rem;
    text-align: center;
  }
  
  .offer-page-latest-offers {
    background-color: #FAF6F3;
  }
  
  /* .for-rent,
  .for-sale {
    background-color: #FFFFFF;
  }
   */
  .offer-page-for-rent,
  .offer-page-for-sale {
    background-color: #FFFFFF;
    padding: 4rem 1rem;
    text-align: left; /* Align text in the sections to the left */
  }
  
  .offer-page-for-rent h2,
  .offer-page-for-sale h2 {
    text-align: left; /* Align heading text to the left */
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px; /* Match the max width of the card grid */
    padding-left: 1rem; /* Ensure alignment with cards */
    color: #1428DA;
  }

  .offer-page-latest-offers h2 {
    font-size: 36px;
    font-weight: 600;
    color: #1428DA;
    margin-bottom: 1rem;
  }
  
  .offer-page-latest-offers > p,
  .offer-page-for-rent > p,
  .offer-page-for-sale > p {
    max-width: 800px;
    margin: 0 auto 2rem;
    color: #1A1A1A;
    font-family: Poppins, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 36px;
  }
  

  .offer-page-property-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    justify-content: center;
    max-width: 1200px; 
    margin: 2rem auto;
  }
  
  
  .offer-page-offer-cta {
    margin-top: 2rem;
  }
  
  .offer-page-cta-text {
    color: #1428DA;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .offer-page-learn-more {
    color: #DA7B14;
    text-decoration: none;
  }
  
  /* .load-more {
    background-color: transparent;
    border: 2px solid #DA7B14;
    color: #DA7B14;
    padding: 0.5rem 1rem;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  } */

  .offer-page-load-more {
    display: block; /* Ensure the button behaves like a block element */
    margin: 2rem auto 0 auto; /* Center the button horizontally */
    background-color: transparent;
    border: 2px solid #DA7B14;
    color: #DA7B14;
    padding: 0.5rem 1rem;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .offer-page-load-more:hover {
    background-color: #DA7B14;
    color: #FFFFFF;
  }
  
  .offer-page-inquiry {
    background-size: cover;
    background-position: center;
    padding: 4rem 1rem;
    color: #FFFFFF;
  }
  
  .offer-page-inquiry-content {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .offer-page-inquiry-text {
    flex: 1;
    padding-right: 2rem;
  }
  
  .offer-page-inquiry-text h2 {
    font-size: 36px;
    margin-bottom: 1rem;
  }
  
  .offer-page-inquiry-text p {
    font-size: 18px;
    margin-bottom: 2rem;
  }
  
  .offer-page-inquiry-text hr {
    width: 96px;
    height: 4px;
    background-color: #FFFFFF;
    border: none;
    margin-bottom: 2rem;
  }
  
  .offer-page-inquiry-steps {
    display: flex;
    justify-content: space-between;
  }
  
  .offer-page-step {
    flex: 1;
  }
  
  .offer-page-step h3 {
    font-size: 24px;
    margin-bottom: 1rem;
  }
  
  .offer-page-inquiry-form {
    flex: 1;
    background-color: #FFFFFF;
    padding: 2rem;
    border-radius: 8px;
  }
  
  .offer-page-inquiry-form h3 {
    color: #1428DA;
    font-size: 24px;
    margin-bottom: 1rem;
  }
  
  .offer-page-form-group {
    margin-bottom: 1rem;
  }
  
  .offer-page-form-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: #1A1A1A;
  }
  
  .offer-page-form-group input,
  .offer-page-form-group select,
  .offer-page-form-group textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .offer-page-inquiry-form button {
    width: 100%;
    background-color: #1428DA;
    color: #FFFFFF;
    border: none;
    padding: 1rem;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .offer-page-inquiry-form button:hover {
    background-color: #0F1FA3;
  }
  
  @media (max-width: 768px) {
    .offer-page-property-grid {
      grid-template-columns: 1fr;
    }
  
    .offer-page-inquiry-content {
      flex-direction: column;
    }
  
    .offer-page-inquiry-text,
    .offer-page-inquiry-form {
      padding: 0;
      margin-bottom: 2rem;
    }
  
    .offer-page-inquiry-steps {
      flex-direction: column;
    }
  
    .offer-page-step {
      margin-bottom: 1rem;
    }
  }