.blog-hero-section {
  position: relative;
  width: 100%;
  height: 300px; 
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f1f1f1;
  overflow: hidden;
  padding: 50px calc((100vw - 1200px) / 2);
  color: #fff; 
}

/* Image Overlay */
.blog-hero-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
}

/* Background Image */
.blog-hero-carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.5s ease-in-out;
}

/* Overlay Effect */
.blog-hero-image-overlay::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Light black overlay */
  z-index: 2;
}

/* Centralized Hero Content */
.blog-hero-content {
  position: relative;
  z-index: 3;
  text-align: center;
  max-width: 600px;
  margin-right: 20%;
  margin-top: 50px;
}

/* Content Styling */
.blog-hero-content h1 {
  font-size: 48px;
  margin-bottom: 15px;
}

.blog-hero-content p {
  font-size: 20px;
  margin-bottom: 25px;
  color: #f1f1f1;
}

/* CTA Button */
.blog-cta-button {
  background-color: #3C4DE0;
  color: #fff;
  border: none;
  padding: 1rem 2rem;
  font-size: 18px;
  cursor: pointer;
  display: inline-block;
}

.blog-cta-button:hover {
  background-color: #2a3bb0;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .blog-hero-section {
    height: 450px;
    padding: 30px 20px;
  }

  .blog-hero-content h1 {
    font-size: 36px;
  }

  .blog-hero-content p {
    font-size: 16px;
  }
}

@media (max-width: 576px) {
  .blog-hero-section {
    padding: 1.5rem;
    height: 400px;
  }

  .blog-hero-content h1 {
    font-size: 28px;
  }

  .blog-hero-content p {
    font-size: 14px;
  }
}



/* .blog-hero-section {
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
  background-color: #f1f1f1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px calc((100vw - 1200px) / 2);
  overflow: hidden;
}

.blog-hero-content {
  flex: 1;
  padding: 0 40px;
}

.blog-hero-content h1 {
  font-size: 32px;
  margin-bottom: 10px;
}

.blog-hero-content p {
  font-size: 18px;
  margin-bottom: 20px;
}


.blog-hero-image {
  max-width: 100%;
  height: auto;
  object-fit: contain;
  margin-right: 400px;
  margin-top: 50px;
}

@media (max-width: 768px) {

  .blog-hero-section {
    flex-direction: column;
    padding: 30px 20px;
  }

  .blog-hero-image {
    max-width: 100%;
    margin-top: 20px;
    margin-left: 350px;
  }
} */
