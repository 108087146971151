.property-detail-page {
    width: 100%;
    margin: 0 auto;
    padding: 2rem;
    background-color: #FFFFFF;
    box-sizing: border-box;
  }
  
  .property-detail-page-hero-section {
    margin-bottom: 2rem;
    padding-top: 80px;
  }


  .property-detail-page-hero-content {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    align-items: stretch;
  }

  .property-detail-page-hero-left {
    flex: 1;
    min-width: 0; 
  }
  
  .property-detail-page-hero-header {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .property-detail-page-hero-header h1 {
    margin-right: 1rem;
  }
  
  .property-detail-page-property-tag {
    background-color: #1428DA;
    color: #FFFFFF;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    font-size: 0.875rem;
  }
  

  
  .property-detail-page-image-nav-button:hover {
    background: rgba(0, 0, 0, 0.7);
  }
  
  .property-detail-page-image-nav-button.prev {
    left: 10px;
  }
  
  .property-detail-page-image-nav-button.next {
    right: 10px;
  }


  .property-detail-page-carousel-thumbnail-wrapper.active {
    border-color: #1428DA; /* Match your brand color */
    transform: scale(1.05); /* Slight scale effect for active thumbnail */
  }
  
  .property-detail-page-carousel-thumbnail:hover {
    opacity: 0.8;
  }

  
  .property-detail-page-property-info {
    display: flex;
    background-color: #FFFFFF;
    padding: 1rem;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .property-detail-page-info-column {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  

  .property-detail-page-hero-right {
    width: 432px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
  }
  

  .property-detail-page-overview-section {
    background-color: #FFFFFF !important;
    border-radius: 15px;
    padding: 0;
    margin-bottom: 2rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    pointer-events: none;
  }

/* Remove card-like appearance from sections */
.property-detail-page-overview-section, 
.property-detail-page-property-details-section, 
.property-detail-page-property-description-section {
    background-color: #FFFFFF;
    margin-bottom: 2rem;
    padding: 0; 
    box-shadow: none; 
    border-radius: 0; 
    width: 100%;
}

/* Ensure text is always visible */
.property-detail-page-overview-section:hover, 
.property-detail-page-property-details-section:hover, 
.property-detail-page-property-description-section:hover {
    background-color: #FFFFFF;
    color: #000; /* Ensure text is always visible */
}

  .property-detail-page-property-description {
    line-height: 1.6;
    color: #333;
}

/* Property Details Section - Layout for two columns */
.property-detail-page-property-details-section {
  width: 100%;
  margin-bottom: 2rem;
}

  .property-detail-page-property-details-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two equal columns */
    gap: 2rem; /* Space between columns */
}
  
  .property-detail-page-property-detail-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}
  
  .property-detail-page-detail-label {
    font-weight: bold;
    margin-right: 0.5rem;
  }
  
  .property-detail-page-detail-value {
    color: #666;
  }

/* Fix any hover issue with disappearing text */
.property-detail-page-property-detail-item:hover .detail-label, 
.property-detail-page-property-detail-item:hover .detail-value {
    color: #666; /* Maintain consistent text color */
}
  
  .property-detail-page-property-description {
    line-height: 1.6;
    color: #333;
  }
  

  .property-detail-page-section-header {
    background-color: #F3F3F3 !important;
    color: #000;
    font-size: 32px;
    padding: 1rem 2rem;
    margin: 0;
    border-radius: 15px 15px 0 0;
    text-align: left;
    width: 100%;
    box-sizing: border-box;
  }
  

  .property-detail-page-overview-content,
  .property-detail-page-property-details-content,
  .property-detail-page-property-description {
    padding: 2rem;
  }


  .property-detail-page-overview-content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Make columns flexible */
    gap: 2rem;
    align-items: center; 
}


  .property-detail-page-overview-item {
    display: flex;
    flex-direction: column;
    justify-content: center;

}

  .property-detail-page-overview-item span {
    color: #666;
    font-size: 0.9rem;
    
  }
  
  .property-detail-page-overview-item strong {
    color: #000;
    font-size: 1rem;
  }
  

  .property-detail-page-feature {
    display: flex;
    flex-direction: column;
  }

  .property-detail-page-feature-item {
    display: flex;
    flex-direction: row;
  } 
  
  .property-detail-page-feature img {
    width: 20px;
    height: 20px;
    margin-bottom: 0.25rem;
    margin-right: 0.55rem;
  }
  
  .property-detail-page-featured-section {
    text-align: center;
    margin-bottom: 2rem;
    border-radius: 15px;
    padding: 2rem;
  }

  .property-detail-page-featured-carousel .property-detail-page-slick-slide {
    transition: transform 0.5s ease-in-out;
  }
  
  .property-detail-page-slick-center .property-detail-page-carousel-item {
    transform: scale(1.1); /* Scale the center item */
    z-index: 1; 
  }
  
  .property-detail-page-carousel-item {
    transition: all 0.3s ease;
    opacity: 0.8; 
  }
  
  .property-detail-page-slick-center .property-detail-page-carousel-item {
    opacity: 1; /* Make the center card fully visible */
  }
  
  .property-detail-page-carousel-dots {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
  }

  .property-detail-page-section-header {
    color: #1428DA !important;
    /* text-align: center; */
  }

  .property-detail-page-featured-section-header {
    color: #1428DA !important;
    text-align: center;
  }

  .slick-slide {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
  
  .slick-track {
    display: flex !important;
    align-items: center;
  }
  
  .property-detail-page-carousel-item {
    width: auto !important;
    margin: 0 15px; /* Add some spacing between items */
  }
  
  .property-detail-page-carousel-item {
    transition: all 0.3s ease;
  }
  
  .property-detail-page-carousel-item.active {
    transform: scale(1.05);
  }
  
  .property-detail-page-carousel-item:not(.active) {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .property-detail-page-carousel-dots {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
  }

  
  @media (max-width: 1024px) {
    .property-detail-page-hero-content {
      flex-direction: column;
    }
    .property-detail-page-hero-right {
        width: 100%;
        margin-top: 2rem;
      }
      .property-detail-page-slick-slide {
        transform: scale(0.9);
      }
  }
  
  @media (max-width: 768px) {
    .property-detail-page-overview-content {
      flex-wrap: wrap;
    }
  
    .property-detail-page-overview-item {
      flex-basis: 50%;
      margin-bottom: 1rem;
    }

    .property-detail-page-property-detail-item {
      flex: 1 1 calc(50% - 2rem);
    }

    .property-detail-page-slick-slide {
      transform: scale(0.8);
    }
  }
  
  @media (max-width: 480px) {
    .property-detail-page-property-info {
      flex-direction: column;
    }
  
    .property-detail-page-info-column {
      margin-bottom: 1rem;
    }

    .property-detail-page-property-detail-item {
      flex: 1 1 100%;
      margin-right: 0;
    }
  }