.app {
  font-family: Arial, sans-serif;
  max-width: 100%;
  overflow-x: hidden;
}

.loading, .error {
  text-align: center;
  margin-top: 50px;
  font-size: 18px;
}

.error {
  color: red;
}

/* ==== adminScreen styles ==== */
.admin {
  margin: 80px 0 0 100px;
  display: flex;
  height: 100vh;
  background-color: #f0f2f5;
}

.admin-main-content {
  flex-grow: 1;
  overflow-y: auto;
}


/* ==== end adminScreen styles ==== */

main {
  padding-top: 20px;
}

/* Color variables */
:root {
  --primary-color: #2697FF;
  --secondary-color: #1E1E2D;
  --background-color: #f0f2f5;
  --text-color: #333;
  --light-text-color: #777;
  --light-text: #6b7280;
  --white: #ffffff;
  --background: #f9fafb;
  --card-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);


 /* Typography */
 --font-size-sm: 0.875rem;
 --font-size-base: 1rem;
 --font-size-lg: 1.125rem;
 --font-size-xl: 1.25rem;
 --font-size-2xl: 1.5rem;
 --font-size-3xl: 2rem;
 --font-size-4xl: 2.5rem;
 
 /* Spacing */
 --spacing-1: 0.25rem;
 --spacing-2: 0.5rem;
 --spacing-3: 0.75rem;
 --spacing-4: 1rem;
 --spacing-6: 1.5rem;
 --spacing-8: 2rem;
 --spacing-12: 3rem;
 --spacing-16: 4rem;
 --spacing-20: 5rem;
 
 /* Borders */
 --border-radius-sm: 0.25rem;
 --border-radius-md: 0.5rem;
 --border-radius-lg: 0.75rem;
 --border-radius-full: 9999px;
 
 /* Shadows */
 --shadow-sm: 0 1px 2px rgba(0, 0, 0, 0.05);
 --shadow-md: 0 4px 6px rgba(0, 0, 0, 0.1);
 --shadow-lg: 0 10px 15px rgba(0, 0, 0, 0.1);
 
 /* Container */
 --container-max-width: 1200px;
 --container-padding: 1rem;
}


body {
  font-family: 'Roboto', sans-serif;
  color: var(--text-color);
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

/* Responsive styles */
@media (max-width: 768px) {
  .app {
    flex-direction: column;
  }
}

/* Floating Scroll to Top Button */
.scroll-to-top {
  position: fixed;
  bottom: 30px; /* Adjusts vertical position */
  right: 30px;  /* Adjusts horizontal position */
  z-index: 1000; /* Ensure it's above other content */
}

.scroll-button {
  background-color: #0B167C; /* Your primary brand color */
  color: white;
  border: none;
  padding: 15px;
  border-radius: 50%;
  font-size: 24px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

/* Ensure button is hidden on small screens until scrolled down */
@media (max-width: 768px) {
  .scroll-to-top {
    bottom: 20px;
    right: 20px;
  }
}
