.blog-card {
    /* border: 1px solid #ddd; */
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
  
  .blog-card img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .blog-card-content {
    padding: 15px;
  }
  
  .blog-card h3 {
    margin: 0 0 10px;
    font-size: 18px;
  }
  
  .blog-card-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  
  .blog-card-footer .likes,
  .blog-card-footer .comments {
    display: flex;
    align-items: center;
  }
  
  .blog-card-footer .likes span,
  .blog-card-footer .comments span {
    margin-left: 5px;
  }
  


/* .blog-card {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .blog-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .blog-details {
    padding: 20px;
  }
  
  .blog-details h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .blog-author {
    font-size: 14px;
    color: gray;
  }
  
  .blog-stats {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
   */