/* Container */
.carousel-container {
    position: relative;
    width: 100%;
  }
  
  .carousel-container:hover .carousel-arrow {
    background-color: rgba(0, 0, 0, 0.7);
  }
  
  /* Main Image */
  .carousel-main-image {
    position: relative;
    width: 100%;
    height: 500px;
    background-color: #f3f4f6; /* Equivalent to bg-gray-100 */
    overflow: hidden;
    border-radius: 0.5rem;
  }
  
  .carousel-main-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease;
  }
  
  /* Navigation Arrows */
  .carousel-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 0.5rem;
    border-radius: 9999px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .carousel-arrow-left {
    left: 1rem;
  }
  
  .carousel-arrow-right {
    right: 1rem;
  }
  
  /* Image Counter */
  .carousel-counter {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 0.25rem 0.75rem;
    border-radius: 9999px;
    font-size: 0.875rem;
  }
  
  /* Thumbnails */
  .carousel-thumbnails {
    display: flex;
    gap: 0.5rem;
    margin-top: 1rem;
    overflow-x: auto;
    padding-bottom: 0.5rem;
  }
  
  .carousel-thumbnail-button {
    position: relative;
    flex-shrink: 0;
    width: 5rem;
    height: 5rem;
    border-radius: 0.5rem;
    overflow: hidden;
    transition: opacity 0.3s ease;
  }
  
  .carousel-thumbnail-button:hover {
    opacity: 1;
  }
  
  .carousel-thumbnail-button img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  /* Active Thumbnail */
  .carousel-thumbnail-button.active {
    box-shadow: 0 0 0 2px #3b82f6; /* Equivalent to ring-2 ring-blue-500 */
    opacity: 1;
  }
  
  .carousel-thumbnail-button:not(.active) {
    opacity: 0.7;
  }
  