.featured-videos {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .video-item {
    cursor: pointer;
  }
  
  .video-thumbnail-container {
    position: relative;
    margin-bottom: 0.5rem;
  }
  
  .video-thumbnail {
    width: 100%;
    height: 160px;
    object-fit: cover;
    border-radius: 6px;
  }
  
  .video-duration {
    position: absolute;
    bottom: 8px;
    right: 8px;
    background-color: rgba(0, 0, 0, 0.75);
    color: white;
    font-size: 0.75rem;
    padding: 2px 6px;
    border-radius: 4px;
  }
  
  .video-title {
    font-size: 0.875rem;
    font-weight: 500;
    color: #1a1a1a;
    margin-bottom: 0.25rem;
    transition: color 0.2s ease;
  }
  
  .video-item:hover .video-title {
    color: #2563eb;
  }
  
  .video-views {
    font-size: 0.75rem;
    color: #666;
  }