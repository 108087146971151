 .construction-landing-page {
    width: 100%;
    overflow-x: hidden;
    background-color: #fff;
  }
  
  /* Styling for section headers */
  .construction-landing-page h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 1.5rem;
    text-align: center;
  }
  
  
  /* Responsive layout adjustments */
  @media (max-width: 768px) {
    .construction-landing-page h2 {
      font-size: 1.8rem;
    }
    section {
      padding: 3rem 1rem;
    }
  }