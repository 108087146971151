.newsletter {
    background-color: #FFFFFF;
    padding: 4rem 1rem;
    /* padding: 4rem 2rem; */
    text-align: center;
  }
  
  .newsletter h2 {
    font-size: 48px;
    color: #1428DA;
    margin-bottom: 1rem;
  }
  
  .newsletter p {
    max-width: 800px;
    margin: 0 auto 2rem;
    color: #1A1A1A;
  }
  
  .newsletter form {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }
  
  .newsletter input {
    width: 655px;
    height: 60px;
    padding: 0 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .newsletter button {
    width: 185px;
    height: 60px;
    background-color: #3C4DE0;
    color: #FFFFFF;
    border: none;
    border-radius: 4px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .newsletter button:hover {
    background-color: #2a3bb0;
  }

  @media (max-width: 768px) {
    .newsletter h2 {
      font-size: 32px;
    }
  
    .newsletter form {
      flex-direction: column;
    }
  
    .newsletter input,
    .newsletter button {
      width: 100%;
      margin-bottom: 1rem;
    }
  }

  /* @media (max-width: 768px) {
    .newsletter form {
      flex-direction: column;
    }
  
    .newsletter input,
    .newsletter button {
      width: 100%;
    }
  } */
  
  /* @media (max-width: 768px) {
    .newsletter form {
      flex-direction: column;
    }
  
    .newsletter input,
    .newsletter button {
      width: 100%;
    }
  } */