.construction-admin-screen {
  padding: 24px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin: 2rem;
}

.admin-construction-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.admin-construction-header h2 {
  font-size: 24px;
  font-weight: bold;
  color: #3C4DE0;
}

.admin-construction-btn {
  padding: 8px 16px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
}

.admin-construction-btn-primary {
  background-color: #007bff;
  color: #ffffff;
  border: none;
}

.admin-construction-btn-primary:hover {
  background-color: #0056b3;
}

.admin-construction-btn-icon {
  background-color: transparent;
  border: none;
  padding: 4px;
  margin-right: 8px;
  cursor: pointer;
}

.admin-construction-edit-icon,
.admin-construction-delete-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-size: contain;
  background-repeat: no-repeat;
}

.admin-construction-edit-icon {
  background-image: url('../../../../assets/icons/admin/edit-icon.svg');
}

.admin-construction-delete-icon {
  background-image: url('../../../../assets/icons/admin/delete-icon.svg');
}

.admin-construction-projects-table {
  overflow-x: auto;
}

.admin-construction-projects-table table {
  width: 100%;
  border-collapse: collapse;
}

.admin-construction-projects-table th,
.admin-construction-projects-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #f0f0f0;
}

.admin-construction-projects-table th {
  background-color: #f8f8f8;
  font-weight: bold;
}

.admin-construction-projects-table td {
  color: #555555;
}


/* .dummy-screen {
    padding: 20px;
    background-color: white;
    border-radius: var(--border-radius-md);
    box-shadow: var(--shadow-md);
    color: var(--text-color);
  }
  
  .dummy-screen h2 {
    margin-bottom: 10px;
    font-size: var(--font-size-2xl);
    color: var(--primary-color);
  }
  
  .dummy-screen p {
    font-size: var(--font-size-base);
    color: var(--light-text-color);
  } */