.sidebar {
    width: 250px;
    background-color: white;
    color: var(--text-color);
    padding: 20px 20px 20px 0;
    margin-left: -95px;
  }
  
  .logo h1 {
    font-size: 1.5rem;
    margin-bottom: 30px;
    text-align: center;
  }
  
  .sidebar nav ul {
    list-style-type: none;
    padding: 0 0 0 45px;
  }
  
  .sidebar nav li {
    margin-bottom: 10px;
  }
  
  .sidebar nav button {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px;
    border: none;
    background-color: transparent;
    /* color: white; */
    color: var(--text-color);
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .sidebar nav button:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  .sidebar .icon {
    margin-right: 10px;
    font-size: 1.2rem;
  }
  
  @media (max-width: 768px) {
    .sidebar {
      width: 100%;
      padding: 10px;
    }
  
    .sidebar nav {
      display: flex;
      overflow-x: auto;
    }
  
    .sidebar nav ul {
      display: flex;
    }
  
    .sidebar nav li {
      margin-right: 10px;
      margin-bottom: 0;
    }
  }