.about-features-section {
  padding: 4rem 0;
  margin: 0 100px;
}

.about-features-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
}

.about-feature-card {
  background-color: var(--white);
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: var(--card-shadow);
}

.about-feature-icon-wrapper {
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.about-feature-card h3 {
  color: var(--text-color);
}

/* Responsive */
@media (max-width: 1024px) {
  .about-features-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .about-features-grid {
    grid-template-columns: 1fr;
  }
}


/* .features {
    padding: 5rem 1rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .features h2 {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 3rem;
  }
  
  .features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
  }
  
  .feature-card {
    padding: 1.5rem;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
  }
  
  .feature-icon {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  .feature-card h3 {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
  }
  
  .feature-card p {
    color: #666666;
    margin-bottom: 1rem;
  }

  @media (max-width: 768px) {
    .features-grid{
      grid-template-columns: 1fr;
    }
  }
  
  @media (max-width: 480px) {
    
    .features{
      padding: 3rem 1rem;
    }
  } */